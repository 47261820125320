


















































































































































































































































































































































































































































































































































































































































.el-textarea {
  height: 13rem;
  .el-textarea__inner {
    height: 100%;
    resize: none;
  }
}
// .templateList1 {
//   // .el-cascader {
//   //   height: 32px !important;
//   // }
// }
.tColor1:hover {
  color: #409eff;
}
