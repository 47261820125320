.el-textarea {
  height: 13rem;
}
.el-textarea .el-textarea__inner {
  height: 100%;
  resize: none;
}
.tColor1:hover {
  color: #409eff;
}
